<template>
  <div class="chart-container">
    <p>
      In this plot, you can see your self-reported productivity throughout the
      day:
    </p>
    <div ref="chartdiv" class="chart clear-both"></div>
    <p>Note: Most recent datapoints might be missing</p>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      root: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      let plot_values = this.values;
      // testing code:
      /*plot_values = [];
      let startDate = new Date();
      startDate.setHours(0, 0, 0);
      for (var i = 0; i < 10; i++) {
        let newDate = new Date(startDate);
        newDate.setHours(i, 0, 0);
        plot_values.push({
          value: i,
          date: newDate.getTime(),
        });
      }
      */
      console.log('simpleLineChart:');
      console.log(plot_values);

      let root = am5.Root.new(this.$refs.chartdiv);

      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
          paddingLeft: 0,
        })
      );

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
        })
      );
      cursor.lineY.set('visible', false);

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: 'hour',
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
          }),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            pan: 'zoom',
          }),
          min: 0,
          max: 6,
          strictMinMax: true,
        })
      );

      // Fix the X-axis
      xAxis.start = 0;
      xAxis.end = 24;
      xAxis.strictMinMax = true;

      // Fix the Y-axis
      yAxis.min = 0;
      yAxis.max = 8; // adjust this value as needed
      yAxis.strictMinMax = true;

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Productivity',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}',
          }),
        })
      );

      // Add markers (dots) to the line series
      series.bullets.push(function (root) {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: series.get('fill'),
          }),
        });
      });

      // Set data
      series.data.setAll(plot_values);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);
      chart.appear(1000, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  width: 100%;
  height: 500px;
}
</style>
