<template>
  <div class="bg-gray-800">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="border-b border-gray-700">
        <div
          class="relative flex items-center justify-between h-16 px-4 sm:px-0"
        >
          <div
            class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
          >
            <div class="flex-shrink-0 flex items-center">
              <img
                src="@/assets/logo_white.png"
                alt="SongPulse Logo"
                class="mx-auto h-8 block w-auto"
              />
            </div>
            <div class="hidden sm:block sm:ml-6">
              <div class="flex space-x-4">
                <router-link
                  to="dashboard"
                  active-class="bg-gray-900 text-white"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-square-poll-vertical"
                    class="mr-1"
                  />
                  Dashboard
                </router-link>

                <router-link
                  to="settings"
                  active-class="bg-gray-900 text-white"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  <font-awesome-icon icon="fa-regular fa-gears" class="mr-1" />
                  Settings
                </router-link>
              </div>
            </div>
          </div>
          <div
            class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 hidden md:block"
          >
            <div
              class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 pull-right rounded-md text-sm font-medium cursor-pointer"
              @click="logout"
            >
              Logout
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <div
              class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              @click="showMenu = !showMenu"
            >
              <span class="sr-only">Open main menu</span>
              <font-awesome-icon
                v-if="!showMenu"
                icon="fa-regular fa-bars"
                class="block fa-w-16 h-6 w-6 text-gray-400"
              />
              <font-awesome-icon
                v-if="showMenu"
                icon="fa-regular fa-circle-xmark"
                class="block fa-w-16 h-6 w-6 text-gray-400"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="showMenu" class="border-b border-gray-700 md:hidden">
      <div class="px-2 py-3 space-y-1 sm:px-3">
        <router-link
          to="dashboard"
          active-class="bg-gray-900 text-white"
          class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
        >
          Dashboard
        </router-link>
        <router-link
          to="settings"
          active-class="bg-gray-900 text-white"
          class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
        >
          Settings
        </router-link>
        <div
          class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
          @click="logout"
        >
          Logout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from '@/services/auth/Auth';

export default {
  name: 'NavigationDark',
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    logout() {
      Auth.logout();
    },
  },
};
</script>

<style scoped lang="scss"></style>
