<template>
  <footer class="w-full">
    <div class="p-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-3">
        <a
          href="mailto:cuber@ifi.uzh.ch"
          class="text-gray-600 hover:text-gray-800"
        >
          Help
        </a>
      </div>
      <div class="mt-4 md:mt-0 md:order-1">
        <p class="text-center text-base text-gray-300">
          © {{ currentYear }} UZH
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'CustomFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss"></style>
