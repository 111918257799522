<template>
  <div class="dashboard">
    <h3>Tutorial Videos</h3>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { arrayToCsv, downloadCsv } from '@/services/CsvHelper';
import { mapState } from 'vuex';
export default {
  name: 'DashboardPage',
  components: {},
  data() {
    return {
      isLoading: true,
      dateRange: [
        DateTime.local().startOf('week'),
        DateTime.local().endOf('week'),
      ],
    };
  },
  computed: {
    ...mapState([
      'selfReportDtos',
      'selfReportDataByQuestion',
      'heartRateData',
    ]),
    combinedData() {
      return {
        selfReportDataByQuestion: this.selfReportDataByQuestion,
        heartRateData: this.heartRateData,
      };
    },
    hasData() {
      return this.selfReportDataByQuestion?.length > 0 && !this.isLoading;
    },
  },
  async created() {
    await this.dispatchSelfReportData();
  },
  methods: {
    async onDateRangeChange(dateRange) {
      this.dateRange = [
        DateTime.fromJSDate(dateRange[0]),
        DateTime.fromJSDate(dateRange[1]),
      ];
      await this.dispatchSelfReportData();
    },
    async dispatchSelfReportData() {
      this.isLoading = true;
      await this.$store.dispatch('fetchSelfReportData', {
        start: this.dateRange[0].toISODate(),
        end: this.dateRange[1].toISODate(),
      });

      this.isLoading = false;
    },
    downloadAllDataAsCsv() {
      // get all unique question strings
      const uniqueQuestionStrings = new Set();
      this.selfReportDtos.forEach((dto) => {
        dto.answers
          .map((a) => a.question)
          .forEach((q) => {
            uniqueQuestionStrings.add(q);
          });
      });
      const csvHeader = [
        'completedOnDeviceAt',
        'startedOnDeviceAt',
        'heartRate',
      ];
      csvHeader.push(...Array.from(uniqueQuestionStrings));
      const csvResult = [csvHeader];
      csvResult.push(
        ...this.selfReportDtos.map((d) => [
          d.completedOnDeviceAt,
          d.startedOnDeviceAt,
          d.currentHeartRate,
          ...Array.from(uniqueQuestionStrings).map((q) => {
            const answer = d.answers.find((a) => a.question === q);
            return answer ? answer.answer : '';
          }),
        ])
      );

      downloadCsv(
        arrayToCsv(csvResult),
        `songpulse_export-${this.question}.csv`
      );
    },
  },
};
</script>
<style scoped lang="scss"></style>
