<template>
  <AuthenticationFormContainer title="Fitbit Verification">
    <CustomAlert
      v-if="verified"
      text="You have successfully signed up! You should receive an E-Mail shortly. Please continue following the setup instructions."
      type="success"
    />
    <CustomAlert
      v-if="errorOccurred"
      text="The provided token was invalid. Please notify a supervisor."
      type="error"
    />
  </AuthenticationFormContainer>
</template>
<script>
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import CustomAlert from '@/components/CustomAlert';
import Http from '@/config/Http';
export default {
  name: 'FbCallbackPage',
  components: { CustomAlert, AuthenticationFormContainer },
  data() {
    return {
      verified: false,
      errorOccurred: false,
    };
  },
  mounted() {
    this.fbCallback();
  },

  methods: {
    async fbCallback() {
      const params = this.$route.query;
      console.log(params);
      if (params.code && params.state) {
        try {
          // let server know and wait for its response
          // http://localhost:8080/fb_callback?code=101bb7ab9ffce683a227e0e73cbdc021ec71da97&state=177161198f702f40#_=_

          const response = await Http.post('/auth/register/fitbit_return', {
            code: params.code,
            state: params.state,
          });
          console.log(response);
          if (response.data.ok === 1) {
            this.verified = true;
            this.errorOccurred = false;
          } else {
            this.errorOccurred = true;
          }
        } catch (e) {
          this.verified = false;
          this.errorOccurred = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.registration {
}
</style>
