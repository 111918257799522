<template>
  <div class="landing">
    <section>
      <div class="mx-auto max-w-7xl">
        <div
          class="container max-w-lg px-4 py-32 mx-auto text-left md:max-w-none md:text-center"
        >
          <h1
            class="text-5xl font-extrabold leading-10 tracking-tight text-center text-gray-900 md:text-center sm:leading-none md:text-6xl lg:text-7xl"
          >
            <span class="block">Welcome to Sandman</span>
            <span
              class="relative mt-2 py-2 text-transparent bg-clip-text bg-gradient-to-br from-blue-600 to-blue-500 block"
              >Sleep Study</span
            >
          </h1>
          <div
            class="mx-auto mt-5 text-gray-500 md:mt-12 md:max-w-lg md:text-center lg:text-lg"
          >
            Set up your watch to start the study and contribute to valuable
            research. Your input is crucial as we explore the world of sleep
            patterns.
          </div>
          <div class="flex flex-col items-center mt-12 text-center">
            <span class="relative inline-flex md:w-auto">
              <router-link to="/register" class="btn btn-primary rounded">
                Start Setup
              </router-link>
            </span>
            <a
              href="https://hasel.dev/wp-content/uploads/2024/02/sleep-study-consent-form.pdf"
              class="mt-3 text-sm text-blue-500"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  components: {},
};
</script>
