<template>
  <div class="bg-white border rounded-lg px-8 py-6 mx-auto my-8 max-w-2xl">
    <h2 class="text-2xl font-medium mb-4">Evening Questionnaire</h2>

    <h3 v-if="showError">
      Failed loading survey data: {{ errorMessage }}! Please retry.
    </h3>
    <h3 v-if="showThanks">Survey submitted successfully. Thanks!</h3>
    <div v-if="showSurvey" class="space-y-6">
      <!-- prod chart -->
      <div v-if="showPlot">
        <SimpleLineChart :values="productivityValues" />
      </div>
      <!-- Likert Scale Questions -->
      <div class="space-y-4">
        <LikertScaleQuestion
          v-for="(q, index) in likertScaleQuestions"
          :key="index"
          :question="q"
          @update="updateLikertScaleValue(index, $event)"
        />
      </div>

      <!-- task choice -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          How did you choose what to work on today?
        </label>
        <textarea
          v-model="taskChoice"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="5"
        ></textarea>
      </div>

      <!-- workLocation -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Where did you spend the majority of your time working today?
        </label>
        <select
          v-model="workLocation"
          name="workLocationSelect"
          class="border border-gray-400 p-2 w-full rounded-lg"
        >
          <option value="-1">Please choose an option</option>
          <option value="0">At the office</option>
          <option value="1">At home</option>
          <option value="2">Somewhere else (remotely)</option>
        </select>
      </div>

      <!-- Sleep Impact Question -->
      <div class="mb-4">
        <div v-if="sleepDataAvailable">
          <label class="block text-gray-700 font-medium mb-2">
            You slept {{ hoursSlept }} last night. Did that have any impact on
            your day?
          </label>
        </div>
        <div v-else>
          <label class="block text-gray-700 font-medium mb-2">
            There is no sleep data available for last night. Did your sleep have
            any impact on your day?
          </label>
        </div>
        <input
          id="sleep_time_res"
          v-model="sleepImpact"
          type="text"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
        />
      </div>

      <!-- task quantity -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          How many development tasks / issues / user stories / bugs did you work
          on today? (Note, whatever you use based on your team’s practice, it
          would be great if you keep reporting on the same units.)
        </label>
        <textarea
          v-model="taskQuantityWorkedOn"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="1"
        ></textarea>
      </div>

      <!-- numStoryPoints -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          How many story points did you complete today?
        </label>
        <textarea
          v-model="numStoryPoints"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="1"
        ></textarea>
      </div>

      <!-- task quantity completed -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          How many development tasks / issues / user stories / bugs did you
          complete today?
        </label>
        <textarea
          v-model="taskQuantityCompleted"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="1"
        ></textarea>
      </div>

      <!-- how many commits -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          How many commits did you perform today?
        </label>
        <textarea
          v-model="numCommits"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="1"
        ></textarea>
      </div>

      <!-- Additional Comments Question -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Would you like to share anything else about your day?
        </label>
        <textarea
          v-model="miscRes"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="5"
        ></textarea>
      </div>

      <div v-if="showFormIncomplete">
        <div
          class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span class="block sm:inline"
            >Please provide a value for {{ incompleteField }}
          </span>
        </div>
      </div>

      <!-- Submit Button -->
      <div>
        <button
          class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          @click="submitForm"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/config/Http';
import * as q from '@/config/LikertScaleQuestions';
import LikertScaleQuestion from '@/components/LikertScaleQuestion';
import SimpleLineChart from '@/components/SimpleLineChart';
export default {
  name: 'EveningQuestionnaire',
  components: { LikertScaleQuestion, SimpleLineChart },
  data() {
    return {
      sleepDataAvailable: false,
      hoursSlept: -1,
      miscRes: '',
      hoursSleptRes: '',
      showError: false,
      errorMessage: '',
      showSurvey: true,
      showThanks: false,
      showPlot: false,
      showFormIncomplete: false,
      incompleteField: '',
      taskChoice: '',
      taskQuantityWorkedOn: '',
      numStoryPoints: '',
      taskQuantityCompleted: '',
      numCommits: '',
      sleepImpact: '',
      anythingComment: this.miscRes,
      workLocation: -1,
      likertScaleQuestions: [
        q.likertOverallProductivity,
        q.likertOverallAttention,
        q.likertOverallDistractability,
        q.likertOverallTemporal,
        q.likertOverallWork,
        q.likertOverallMotivation,
        q.likertOverallPlan,
        q.likertOverallFit,
        q.likertOverallStress,
      ],
      likertScaleAnswerMap: {},
      productivityValues: [],
    };
  },
  mounted() {
    this.fetchSleepData();
  },

  methods: {
    likertScaleQuestionsExceptFirst() {
      return this.likertScaleQuestions.slice(1);
    },
    updateLikertScaleValue(index, value) {
      this.likertScaleAnswerMap[value.question_id] = value.answer_id;
    },
    async fetchSleepData() {
      const token = this.$route.params.token;

      const response = await Http.get(`/fitbit/survey/${token}`).catch(
        (error) => {
          if (error.response && error.response.status === 400) {
            this.showError = true;
            this.errorMessage = 'invalid survey token!';
            return;
          }
          this.hoursSlept = -1;
          this.sleepDataAvailable = false;
        }
      );

      if (!response) {
        this.showError = true;
        this.showSurvey = false;
        this.errorMessage = 'Unknown error while fetching sleep data!';
        return;
      }

      if (response.data.error === 'invalid id') {
        this.showError = true;
        this.showSurvey = false;
        this.errorMessage = 'Error: Invalid Survey ID';
        return;
      }

      if (response.data.error === 'already submitted') {
        this.showThanks = true;
        this.showSurvey = false;
        return;
      }

      if (response.data.error === 'no data') {
        this.hoursSlept = '???';
        this.sleepDataAvailable = false;
      } else {
        let minutesSlept = response.data.sleepDuration % 60;
        let hoursSlept = Math.floor(response.data.sleepDuration / 60);
        this.hoursSlept =
          '' + hoursSlept + ' hours and ' + minutesSlept + ' minutes';
        this.efficiency = response.data.efficiency;
        this.sleepDataAvailable = true;

        // check if we got self-report data
        if (
          response.data.productivityAnswers &&
          response.data.productivityAnswers.length > 0
        ) {
          const likertScaleOptionIdToValue = {
            '6d9a2891-e31e-45e1-a5a6-3e37303ae37f': 7,
            'bbb365a6-7e59-4cbf-aafc-5c073df9e037': 6,
            'c5f40ac5-d297-460e-b116-c3ffe0215340': 5,
            '00d393f4-14ad-45c0-a32a-6c3eca4d33e8': 4,
            '0fa3d3d9-f6ff-4c06-8135-4354eca6a6a5': 3,
            '45cbe585-80dd-463b-bb1b-97a09bb78ce7': 2,
            '9e28de98-a367-45bc-8521-21146594630a': 1,
            'fec01145-1e39-434d-8087-60380260ed0a': 0,
          };
          // we receive an productivityAnswers is an array of objects:
          // { completedOnDeviceAt: timestamp, answer: Answer }
          // Answer: { ... likertScaleOptionId: string ...}
          // this means, here we need to parse all answers and create an array that is like {date: timestamp, value: int}
          this.productivityValues = [];
          for (const r of response.data.productivityAnswers) {
            console.log(r);
            if (r.answer.likertScaleOptionId in likertScaleOptionIdToValue) {
              let plotVal = {
                date: new Date(r.completedOnDeviceAt).getTime(),
                value:
                  likertScaleOptionIdToValue[r.answer.likertScaleOptionId] + 1,
              };
              this.productivityValues.push(plotVal);
            } else {
              console.log('Error: unknown LS option ID: ');
              console.log(r);
            }
          }
          this.showPlot = true;
        }
      }
    },
    async submitForm() {
      this.showFormIncomplete = false;
      const mandatoryFields = [this.workLocation];
      const mandatoryFieldStrings = ['Work Location'];

      const token = this.$route.params.token;

      // verify mandatory input fields
      for (var j = 0; j < mandatoryFields.length; j++) {
        if (mandatoryFields[j] === -1) {
          this.showFormIncomplete = true;
          this.incompleteField = mandatoryFieldStrings[j];
          return;
        }
      }

      // get values from each likertscale Question
      let likertScaleAnswers = [];
      for (var i = 0; i < this.likertScaleQuestions.length; i++) {
        let q = this.likertScaleQuestions[i];
        if (!this.likertScaleAnswerMap[q.id]) {
          this.showFormIncomplete = true;
          this.incompleteField = 'all questions';
          return;
        }
        likertScaleAnswers.push({
          questionId: q.id,
          likertScaleOptionId: this.likertScaleAnswerMap[q.id],
        });
      }

      let responseData = {
        taskChoice: this.taskChoice,
        taskQuantityWorkedOn: this.taskQuantityWorkedOn,
        numStoryPoints: this.numStoryPoints,
        taskQuantityCompleted: this.taskQuantityCompleted,
        numCommits: this.numCommits,
        sleepImpact: this.sleepImpact,
        anythingComment: this.miscRes,
        workLocation: parseFloat(this.workLocation),

        answers: likertScaleAnswers,
      };

      // submit response
      const response = await Http.post(
        `/fitbit/survey/${token}`,
        responseData
      ).catch((error) => {
        this.showError = true;
        this.errorMessage = 'failed to submit survey! Please retry';
        console.log(error);
      });

      if (response.error || response.data.error) {
        console.log(response.error);
      }

      if (response.status >= 200 && response.status <= 300) {
        this.showSurvey = false;
        this.showThanks = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.likert-scale-options-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Increase spacing between Likert Scale Questions and the next section */
}

.evening_q {
  margin-bottom: 100px; /* Add spacing between questions */
}

.evening_q p {
  margin-bottom: 30px; /* Add more spacing between question text and input field */
}

/* Style the input fields */
input[type='number'],
input[type='text'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
}

.btn {
  margin-top: 30px; /* Add more spacing between the questions and the Submit button */
}
</style>
