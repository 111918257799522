<template>
  <div class="bg-white border rounded-lg px-8 py-6 mx-auto my-8 max-w-2xl">
    <h2 class="text-2xl font-medium mb-4">Morning Questionnaire</h2>

    <h3 v-if="showError">
      Failed loading survey data: {{ errorMessage }}! Please retry.
    </h3>
    <h3 v-if="showThanks">Survey submitted successfully. Thanks!</h3>
    <div v-if="showSurvey" class="space-y-10 items-center">
      <!-- sleepDeepLight -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          My sleep last night was...
        </label>
        <div
          class="slider_container inline-flex items-center space-y-0 space-x-3"
          style=""
        >
          <p>light</p>
          <input
            v-model="sleepDeepLight"
            type="range"
            min="0"
            max="100"
            value="50"
            class="slider"
          />
          <p>deep</p>
        </div>
      </div>

      <!-- lastNightFellAsleep -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Last night, the first time I got to sleep I...
        </label>
        <div
          class="slider_container inline-flex items-center space-y-0 space-x-3"
          style=""
        >
          <p>just never could fall asleep</p>
          <input
            v-model="lastNightFellAsleep"
            type="range"
            min="0"
            max="100"
            value="50"
            class="slider"
          />
          <p>fell asleep almost immediately</p>
        </div>
      </div>

      <!-- lastNightWasAwake -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Last night I was awake...
        </label>
        <div
          class="slider_container inline-flex items-center space-y-0 space-x-3"
          style=""
        >
          <p>all night long</p>
          <input
            v-model="lastNightWasAwake"
            type="range"
            min="0"
            max="100"
            value="50"
            class="slider"
          />
          <p>very little</p>
        </div>
      </div>

      <!-- lastNightWokeUp -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Last night, when I woke up or was awakened I...
        </label>
        <div
          class="slider_container inline-flex items-center space-y-0 space-x-3"
          style=""
        >
          <p>couldn't get back to sleep</p>
          <input
            v-model="lastNightWokeUp"
            type="range"
            min="0"
            max="100"
            value="50"
            class="slider"
          />
          <p>got back to sleep immediately</p>
        </div>
      </div>

      <!-- describeSleepAs -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          I would describe my sleep last night as...
        </label>
        <div
          class="slider_container inline-flex items-center space-y-0 space-x-3"
          style=""
        >
          <p>a bad night's sleep</p>
          <input
            v-model="describeSleepAs"
            type="range"
            min="0"
            max="100"
            value="50"
            class="slider"
          />
          <p>a good night's sleep</p>
        </div>
      </div>

      <!-- didWearSleepMask -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Did you wear a sleep mask last night?
        </label>
        <select
          v-model="didWearSleepMask"
          name="didWearSleepMaskSelect"
          class="border border-gray-400 p-2 w-full rounded-lg"
        >
          <option value="-1">Please choose an option</option>
          <option value="0">Yes</option>
          <option value="1">No</option>
          <option value="2">Partly</option>
        </select>
      </div>

      <!-- whereSleepLastNight -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Where did you sleep last night?
        </label>
        <select
          v-model="whereSleepLastNight"
          name="whereSleepLastNightSelect"
          class="border border-gray-400 p-2 w-full rounded-lg"
        >
          <option value="-1">Please choose an option</option>
          <option value="0">At home</option>
          <option value="1">Somewhere else I usually sleep</option>
          <option value="2">Other</option>
        </select>
      </div>

      <!-- Additional Comments Question -->
      <div class="mb-4">
        <label class="block text-gray-700 font-medium mb-2">
          Any external factors that affected your sleep last night?
        </label>
        <textarea
          v-model="miscRes"
          class="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
          rows="5"
        ></textarea>
      </div>

      <div v-if="showFormIncomplete">
        <div
          class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span class="block sm:inline"
            >Please provide a value for "{{ incompleteField }}"
          </span>
        </div>
      </div>

      <!-- Submit Button -->
      <div>
        <button
          class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          @click="submitForm"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/config/Http';

export default {
  name: 'MorningQuestionnaire',
  components: {},
  data() {
    return {
      sleepDataAvailable: false,
      showSurvey: true,
      showThanks: false,
      showFormIncomplete: false,
      incompleteField: '',

      sleepDeepLight: -1,
      lastNightFellAsleep: -1,
      lastNightWasAwake: -1,
      lastNightWokeUp: -1,
      describeSleepAs: -1,
      didWearSleepMask: -1,
      whereSleepLastNight: -1,
      anythingComment: this.miscRes,
    };
  },
  mounted() {
    this.fetchSleepData();
  },

  methods: {
    updateLikertScaleValue(index, value) {
      this.likertScaleAnswerMap[value.question_id] = value.answer_id;
    },
    async fetchSleepData() {
      const token = this.$route.params.token;

      const response = await Http.get(`/fitbit/morningSurvey/${token}`).catch(
        (error) => {
          if (error.response && error.response.status === 400) {
            this.showError = true;
            this.errorMessage = 'invalid survey token!';
            return;
          }
          this.hoursSlept = -1;
          this.sleepDataAvailable = false;
        }
      );

      if (!response) {
        this.showError = true;
        this.showSurvey = false;
        this.errorMessage = 'Unknown error while verifying survey token!';
        return;
      }

      if (response.data.error === 'invalid id') {
        this.showError = true;
        this.showSurvey = false;
        this.errorMessage = 'Error: Invalid Survey ID';
        return;
      }

      if (response.data.error === 'already submitted') {
        this.showThanks = true;
        this.showSurvey = false;
        return;
      }
    },
    async submitForm() {
      this.showFormIncomplete = false;
      const mandatoryFields = [
        this.sleepDeepLight,
        this.lastNightFellAsleep,
        this.lastNightWasAwake,
        this.lastNightWokeUp,
        this.describeSleepAs,
        this.didWearSleepMask,
        this.whereSleepLastNight,
      ];
      const mandatoryFieldStrings = [
        'My sleep last night was',
        'Last night, the first time I got to sleep I...',
        'Last night I was awake...',
        'Last night, when I woke up or was awakened I...',
        'I would describe my sleep last night as...',
        'Did you wear a sleep mask last night?',
        'Where did you sleep last night?',
      ];

      const token = this.$route.params.token;

      // verify mandatory input fields
      for (var i = 0; i < mandatoryFields.length; i++) {
        if (mandatoryFields[i] === -1) {
          this.showFormIncomplete = true;
          this.incompleteField = mandatoryFieldStrings[i];
          return;
        }
      }

      let comment = this.miscRes;
      if (!comment) {
        comment = '';
      }

      let responseData = {
        sleepDeepLight: parseInt(this.sleepDeepLight),
        lastNightFellAsleep: parseInt(this.lastNightFellAsleep),
        lastNightWasAwake: parseInt(this.lastNightWasAwake),
        lastNightWokeUp: parseInt(this.lastNightWokeUp),
        describeSleepAs: parseInt(this.describeSleepAs),
        didWearSleepMask: parseInt(this.didWearSleepMask),
        whereSleepLastNight: parseInt(this.whereSleepLastNight),
        anythingComment: comment,
      };

      // submit response
      const response = await Http.post(
        `/fitbit/morningSurvey/${token}`,
        responseData
      ).catch((error) => {
        this.showError = true;
        this.errorMessage = 'failed to submit survey! Please retry';
        console.log(error);
      });

      if (response.error || response.data.error) {
        this.showError = true;
        console.log(response.error);
      }

      if (response.status >= 200 && response.status <= 300) {
        this.showSurvey = false;
        this.showThanks = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.likert-scale-options-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Increase spacing between Likert Scale Questions and the next section */
}

.evening_q {
  margin-bottom: 100px; /* Add spacing between questions */
}

.evening_q p {
  margin-bottom: 30px; /* Add more spacing between question text and input field */
}

/* Style the input fields */
input[type='number'],
input[type='text'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
}

.btn {
  margin-top: 30px; /* Add more spacing between the questions and the Submit button */
}
</style>
