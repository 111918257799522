import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import {
  //faInfoCircle,
  faCalendarAlt,
  faCheckCircle,
  //faClipboardList,
  //faClipboardCheck,
  faCircleXmark,
  //faFileCsv,
  //faSquarePollVertical,
  //faGears,
  //faBars,
} from '@fortawesome/free-regular-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(
  //faInfoCircle,
  faCalendarAlt,
  faCheckCircle,
  //faClipboardList,
  //faClipboardCheck,
  faCircleXmark
  //faFileCsv,
  //faSquarePollVertical,
  //faGears,
  //faBars
);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

import '@/styles/global.scss';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
