<template>
  <AuthenticationFormContainer title="Start Setup">
    <CustomAlert
      v-if="errors.length > 0"
      class="mb-2"
      type="error"
      :text="backendValidationErrorMessage"
    />

    <form class="space-y-6" method="post" @submit="submitForm">
      <div class="field">
        <label for="email">Email</label>
        <div class="mt-1">
          <input
            id="email"
            v-model="inputEmail"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            autofocus
          />
        </div>
      </div>
      <div class="field">
        <label for="username">Participant ID</label>
        <div class="mt-1">
          <input
            id="username"
            v-model="inputUsername"
            name="username"
            type="text"
            autocomplete="username"
            required=""
          />
        </div>
      </div>

      <div class="field">
        <label for="password">Password</label>
        <div class="mt-1">
          <input
            id="password"
            v-model="inputPassword"
            name="password"
            type="password"
            autocomplete="new-password"
            required=""
          />
          <small class="text-gray-300">(minimum 6 characters)</small>
        </div>
      </div>

      <div class="field">
        <label for="password_confirmation">Password Confirmation</label>
        <div class="mt-1">
          <input
            id="password_confirmation"
            v-model="inputPasswordConfirmation"
            name="password_confirmation"
            type="password"
            autocomplete="new-password"
            required=""
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input
            id="study-consent"
            v-model="studyConsentGiven"
            name="study-consent"
            type="checkbox"
            required=""
          />
          <label for="study-consent" class="ml-2 block text-sm text-gray-900">
            I have read and agree to the
            <a
              href="https://hasel.dev/wp-content/uploads/2024/02/sleep-study-consent-form.pdf"
            >
              Study Terms
            </a>
          </label>
        </div>
      </div>

      <div class="field">
        <button type="submit" class="btn btn-primary full-width">
          Sign Up
        </button>
      </div>
    </form>
    <hr class="my-7" />
  </AuthenticationFormContainer>
</template>
<script>
import Auth from '@/services/auth/Auth';
//import Store from '@/store';
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import CustomAlert from '@/components/CustomAlert';
export default {
  name: 'RegistrationPage',
  components: { CustomAlert, AuthenticationFormContainer },
  props: {
    inviteCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputEmail: '',
      inputUsername: '',
      inputPassword: '',
      inputPasswordConfirmation: '',
      inputInvitationCode: this.inviteCode || '',
      studyConsentGiven: false,
      submitting: false,
      backendValidationErrorMessage: '',
      errors: [],
      fieldToStringMap: {
        email: 'Email',
        username: 'Username',
        password: 'Password',
        password_confirmation: 'Password Confirmation',
        studyConsent: 'Study Consent',
      },
    };
  },
  methods: {
    async submitForm(e) {
      const appClientId = '23R5JR'; // fitbit app id

      e.preventDefault();
      this.submitting = true;
      this.errors = [];
      this.backendValidationErrorMessage = '';

      const csrf = Array.from(
        window.crypto.getRandomValues(new Uint8Array(8)),
        (byte) => byte.toString(16).padStart(2, '0')
      ).join('');

      if (this.inputPassword !== this.inputPasswordConfirmation) {
        // TODO: show error
        alert('Passwords do not match');
      } else {
        try {
          console.log('sending request');
          await Auth.registerUser({
            username: this.inputUsername,
            email: this.inputEmail,
            password: this.inputPassword,
            timezone: new window.Intl.DateTimeFormat().resolvedOptions()
              .timeZone,
            studyConsentGiven: this.studyConsentGiven,
            csrfToken: csrf,
          });

          //const account = await Auth.getAccountAndUpdateStore();
          //Store.commit('authorizeUser', account.data);

          // TODO: get csrf token for user
          //Router.push('dashboard');
          // redirect to fitbit to complete registration
          window.location.href = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${appClientId}&scope=activity+cardio_fitness+heartrate+profile+respiratory_rate+settings+sleep&state=${csrf}`;
        } catch (err) {
          console.log('error');
          console.log(err);
          const data = err.response.data;
          if (data.error) {
            this.errors = err.response.data.message;
          }
          if (data.errors) {
            this.errors = data.errors;
          }
          if (this.errors) {
            for (const error of this.errors) {
              if (typeof error === 'string') {
                this.backendValidationErrorMessage += `${error}\n`;
              } else {
                if (error.field && error.message) {
                  this.backendValidationErrorMessage += `${
                    this.fieldToStringMap[error.field]
                  }: ${error.message}\n`;
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>
