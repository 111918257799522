var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-md p-4",class:{
    'bg-red-50': _vm.type === 'error',
    'bg-green-50': _vm.type === 'success',
    'bg-blue-50': _vm.type === 'info',
  }},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[(_vm.type === 'error')?_c('font-awesome-icon',{staticClass:"h-5 w-5 text-red-400",attrs:{"icon":"fa-regular fa-circle-xmark","aria-hidden":"true"}}):_vm._e(),(_vm.type === 'success')?_c('font-awesome-icon',{staticClass:"h-5 w-5 text-green-400",attrs:{"icon":"fa-regular fa-circle-check","aria-hidden":"true"}}):_vm._e(),(_vm.type === 'info')?_c('font-awesome-icon',{staticClass:"h-5 w-5 text-blue-500",attrs:{"icon":"fa-regular fa-info-circle","aria-hidden":"true"}}):_vm._e()],1),_c('div',{staticClass:"ml-3 flex-1 md:flex md:justify-between"},[(_vm.title)?_c('h3',{staticClass:"text-sm font-medium mb-2",class:{
          'text-red-800': _vm.type === 'error',
          'text-green-800': _vm.type === 'success',
          'text-blue-800': _vm.type === 'info',
        }},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('p',{staticClass:"text-sm",class:{
          'text-red-700': _vm.type === 'error',
          'text-green-700': _vm.type === 'success',
          'text-blue-500': _vm.type === 'info',
        }},[_vm._v(" "+_vm._s(_vm.text)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }