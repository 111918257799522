<template>
  <div>
    <p class="mb-2 text-gray-700 font-medium">{{ question.text }}?</p>

    <div class="flex">
      <div
        v-for="(option, index) in question.likertScaleOptions.create
          .slice()
          .reverse()"
        :key="index"
        class="flex flex-col items-center"
      >
        <label
          :for="`likert-option-${index}`"
          class="block text-gray-700 text-xs font-light mt-2 ml-auto w-16 text-center mb-2"
        >
          <input
            :id="`likert-option-${index}`"
            v-model="selectedValue"
            type="radio"
            :value="option.value"
            class="mr-2 focus:outline-none focus:border-blue-400"
            @change="updateParentValue(option)"
          />
          <span class="block">{{ option.label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: null,
      currentSelectionText: '',
    };
  },
  methods: {
    calculateWidth() {
      // Adjust the base width and factor as needed
      const baseWidth = 160;
      const factor = 1.5;
      return (
        baseWidth + this.question.likertScaleOptions.create.length * factor
      );
    },
    updateParentValue(value) {
      // broadcast the number to parent
      this.$emit('update', {
        question_id: this.question.id,
        answer_id: value.id,
        value: value.value,
      });

      // .. but also update the 'current selection text'
      this.currentSelectionText = value.label;
    },
  },
};
</script>

<style scoped>
.likert-radio {
  /* Add styles for radio buttons if needed */
}

.likert-label {
  /* Add styles for option labels if needed */
}
</style>
