<template>
  <div class="settings">
    <div class="mx-auto py-4 my-4 md:w-3/4 space-y-6">
      <h3 class="font-medium text-xl">Device Configuration</h3>
      <CustomAlert
        text="Use your user id and api key to set up your Fitbit watch."
        type="info"
      />
      <div class="field">
        <label for="user-id">User Id</label>
        <div class="mt-1 relative">
          <input
            id="user-id"
            :value="user.id"
            name="email"
            type="text"
            disabled
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
            <font-awesome-icon
              v-if="!userIdCopied"
              class="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
              icon="fa-regular fa-clipboard-list"
              aria-hidden="true"
              @click="copyUserIdToClipboard"
            />
            <font-awesome-icon
              v-else
              class="h-5 w-5 text-green-400 cursor-pointer"
              icon="fa-regular fa-clipboard-check"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div class="field">
        <label for="api-key">API Key</label>
        <div class="mt-1 relative">
          <input
            id="api-key"
            :value="user.apiKey"
            name="api-key"
            type="text"
            disabled
            class="input-with-icon"
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
            <font-awesome-icon
              v-if="!apiKeyCopied"
              class="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
              icon="fa-regular fa-clipboard-list"
              aria-hidden="true"
              @click="copyApiKeyToClipboard"
            />
            <font-awesome-icon
              v-else
              class="h-5 w-5 text-green-400 cursor-pointer"
              icon="fa-regular fa-clipboard-check"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>

      <div class="border-t border-gray-200 py-4 my-4x space-y-6">
        <h3 class="font-medium text-xl">Personal Information</h3>
        <div class="field">
          <label for="username">Username</label>
          <div class="mt-1">
            <input
              id="username"
              :value="user.username"
              name="username"
              type="email"
              disabled
            />
          </div>
        </div>
        <div class="field">
          <label for="email">Email</label>
          <div class="mt-1">
            <input
              id="email"
              :value="user.email"
              name="email"
              type="email"
              disabled
            />
          </div>
        </div>
        <CustomAlert
          v-if="!user.emailVerified"
          class="mt-2"
          :text="`Your email address (${user.email}) is not yet verified.`"
          type="info"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomAlert from '@/components/CustomAlert';
export default {
  name: 'SettingsPage',
  components: { CustomAlert },
  data() {
    return {
      user: this.$store.state.user,
      userIdCopied: false,
      apiKeyCopied: false,
    };
  },
  methods: {
    copyUserIdToClipboard() {
      this.copyToClipboard(this.user.id);
      this.userIdCopied = true;
      setTimeout(() => {
        this.userIdCopied = false;
      }, 1500);
    },
    copyApiKeyToClipboard() {
      this.copyToClipboard(this.user.apiKey);
      this.apiKeyCopied = true;
      setTimeout(() => {
        this.apiKeyCopied = false;
      }, 1500);
    },
    copyToClipboard(str) {
      navigator.clipboard.writeText(str);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-with-icon {
  @apply overflow-ellipsis;
  padding-right: 50px;
}
</style>
