export const likertOverallProductivity = {
  id: '88757a84-cfc8-4f06-8ae1-6be9f610b0c1',
  text: 'How would you rate your overall productivity today',
  likertScaleOptions: {
    create: [
      {
        id: '7d5e2113-1b1e-4186-a627-cd3a71720c1e',
        value: 7,
        label: '5 - Very high',
      },
      {
        id: '483cf993-54d7-45d1-997f-49fc4ba59544',
        value: 6,
        label: '4 - High',
      },
      {
        id: '5cd1801d-2cbc-47d5-84d5-e581bd555414',
        value: 4,
        label: '3 - Medium',
      },
      {
        id: '27f8fe55-0667-42d1-a39b-314dc78e6216',
        value: 2,
        label: '2 - Low',
      },
      {
        id: '6cc16afe-665f-46fe-8475-624098ed7f15',
        value: 1,
        label: '1 - Very low',
      },
      {
        id: '620b1099-b061-44be-895a-c509b7c2b528',
        value: 0,
        label: "I didn't work",
      },
    ],
  },
};

export const likertOverallAttention = {
  id: 'ede384fc-d07c-4e04-9e29-0cd00c0d9c11',
  text: 'How often was it difficult for you today to sustain your attention in work-related tasks',
  likertScaleOptions: {
    create: [
      {
        id: '1476b11d-e011-4549-962e-da5e3f754cc7',
        value: 4,
        label: '4 - Very frequently',
      },
      {
        id: 'bc1b287e-1b23-4693-b282-3a5c1165c28c',
        value: 3,
        label: '3 - Often',
      },
      {
        id: '183d356f-1f3b-47eb-b540-05dcb17b5358',
        value: 2,
        label: '2 - Once in a while',
      },
      {
        id: '6bb90891-1064-4e34-b0cd-e11db7bbe7d6',
        value: 1,
        label: '1 - Never',
      },
    ],
  },
};

export const likertOverallDistractability = {
  id: 'ed37dbb2-97e5-48f2-8de4-72eddceae923',
  text: 'How often were you distracted by extraneous stimuli or irrelevant thoughts throughout the day',
  likertScaleOptions: {
    create: [
      {
        id: '0f2de592-bef3-4938-b10d-8097a3866176',
        value: 4,
        label: '4 - Very frequently',
      },
      {
        id: 'd2a94aa1-e0bd-436f-9b35-79ce9858e5b5',
        value: 3,
        label: '3 - Often',
      },
      {
        id: '3966d4fd-65d6-4d88-9b44-cd6c10bb971b',
        value: 2,
        label: '2 - Once in a while',
      },
      {
        id: '47669354-e07e-4baf-bb8d-fe51159620e1',
        value: 1,
        label: '1 - Never',
      },
    ],
  },
};

export const likertOverallTemporal = {
  id: '8f44bee0-99ad-43e0-b0be-e08ea19e1333',
  text: 'How hurried or rushed was the pace of the tasks you worked on throughout the day',
  likertScaleOptions: {
    create: [
      {
        id: '1a36717c-20da-49a3-a627-17fae0a2c508',
        value: 7,
        label: '5 - Very high',
      },
      {
        id: '22766786-dc6d-427f-b9b6-7cb507bda9f2',
        value: 6,
        label: '4 - High',
      },
      {
        id: '906a5e0f-6c4d-449c-893d-92393d674b29',
        value: 4,
        label: '3 - Medium',
      },
      {
        id: '5730856c-cc8e-4d55-a433-51787a6f125f',
        value: 2,
        label: '2 - Low',
      },
      {
        id: '6e44295d-7c78-43ec-b1e1-f25c24f7a3ff',
        value: 1,
        label: '1 - Very low',
      },
    ],
  },
};

export const likertOverallWork = {
  id: '3bca1f7a-9b4a-4636-a68a-97b4fe6caa15',
  text: 'Did you work on the tasks you meant to work on today',
  likertScaleOptions: {
    create: [
      {
        id: 'ea5b8ed3-7ba4-46c7-be8c-1cf88fd5170c',
        value: 5,
        label: '5 - Always',
      },
      {
        id: '217ad999-07d9-4b71-b9f9-e2e12233387b',
        value: 4,
        label: '4 - Very Often',
      },
      {
        id: 'e0d35e35-2504-4f02-9962-34f6475dc1dc',
        value: 3,
        label: '3 - Sometimes',
      },
      {
        id: 'e78bf907-3bf6-4766-9a9e-5646cbde8558',
        value: 2,
        label: '2 - Rarely',
      },
      {
        id: '9971d406-ecbc-471e-8a89-b67209f9be4a',
        value: 1,
        label: '1 - Never',
      },
    ],
  },
};

export const likertOverallMotivation = {
  id: 'f4217598-2a53-4d5a-a0bf-c172ff536d5b',
  text: 'I felt motivated to complete my work today',
  likertScaleOptions: {
    create: [
      {
        id: '04a13a56-d048-45af-be4a-49ef24e267de',
        value: 4,
        label: '4 - Definitely true',
      },
      {
        id: 'cab46629-0471-42e4-86c5-f208e4905f09',
        value: 3,
        label: '3 - Pretty much true',
      },
      {
        id: '7781ef7e-5eb5-4490-9888-a81537f3edda',
        value: 2,
        label: '2 - Somewhat true',
      },
      {
        id: '2f21f5b2-3367-4d99-b0bd-a89d5d945bb2',
        value: 1,
        label: '1 - Not true',
      },
    ],
  },
};

export const likertOverallPlan = {
  id: '4a031f09-003e-4e7d-8df1-4f9dfe1ec7c8',
  text: 'I completed the work I planned to today',
  likertScaleOptions: {
    create: [
      {
        id: 'cca22f45-38f1-468e-ae94-00533f4cb6f4',
        value: 4,
        label: '4 - Definitely true',
      },
      {
        id: '3630ada6-c314-4240-a2f0-1af3c8bdb0ea',
        value: 3,
        label: '3 - Pretty much true',
      },
      {
        id: 'e53e44d7-1765-4630-8a67-b1b3547b990b',
        value: 2,
        label: '2 - Somewhat true',
      },
      {
        id: '564d5916-5ed0-44ce-a012-2289fe14a668',
        value: 1,
        label: '1 - Not true',
      },
    ],
  },
};

export const likertOverallFit = {
  id: '5e2f0c57-b0ce-43ba-ae44-1f73410100e2',
  text: 'How strongly did you feel in line with how you wished to feel today',
  likertScaleOptions: {
    create: [
      {
        id: 'fb71f9ad-fdbd-4242-9443-1687237138fc',
        value: 10,
        label: '10 - I did not feel at all how I wished to feel',
      },
      {
        id: 'c1d0b850-f165-4553-9b44-d19dcf21152c',
        value: 9,
        label: '9',
      },
      {
        id: '86f1f458-bf5a-4bdb-9cd6-b848abfaab08',
        value: 8,
        label: '8',
      },
      {
        id: 'fc285dbd-cd94-46af-81d6-ef38cb99d0fa',
        value: 7,
        label: '7',
      },
      {
        id: 'd2d9b6e3-c07f-486c-8707-557d58c41f0f',
        value: 6,
        label: '6',
      },
      {
        id: '4cad7444-4ffb-4161-b04c-f9184f7cc109',
        value: 5,
        label: '5',
      },
      {
        id: '3fbb1c76-f915-4e79-bff6-115fc5036cbb',
        value: 4,
        label: '4',
      },
      {
        id: '9d18a7e6-0217-4cd9-850e-2dda5c8c3a5c',
        value: 3,
        label: '3',
      },
      {
        id: '23f89ca4-ba98-4a65-a6aa-d806aeaf472b',
        value: 2,
        label: '2',
      },
      {
        id: 'cc7a70de-933b-406f-b384-7c4aa600be53',
        value: 1,
        label: '1 - I felt exactly how I wished to feel',
      },
    ],
  },
};

export const likertOverallStress = {
  id: '260f71bc-8d3a-4a17-8f90-849c63e9e10c',
  text: 'How would you rate your overall stress level today',
  likertScaleOptions: {
    create: [
      {
        id: '1fdd2b5b-15fb-4bf0-bb69-68e1e0cb9ebd',
        value: 7,
        label: '7 - Very high',
      },
      {
        id: '927c3a27-ab73-4c85-810d-abea9392033f',
        value: 6,
        label: '6 - High',
      },
      {
        id: 'd2573327-a587-4c37-af2f-168e470d77d6',
        value: 5,
        label: '5 - Somewhat high',
      },
      {
        id: '576e3c66-a5bb-4365-9a91-9da6c2ac08cb',
        value: 4,
        label: '4 - Medium',
      },
      {
        id: 'd1bcb3ef-c88b-426f-ab7a-d57759bab8bc',
        value: 3,
        label: '3 - Somewhat low',
      },
      {
        id: '3e87da5a-00da-479b-a0d0-3a86d7321925',
        value: 2,
        label: '2 - Low',
      },
      {
        id: '428cb57b-121c-4282-99c4-d514d3b0e378',
        value: 1,
        label: '1 - Very low',
      },
    ],
  },
};
